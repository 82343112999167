import tinymces from "@/components/tinymces/tinymces.vue";
import WYSIWYG from "@/components/wyswyg/wyswyg.vue";
import commontable from "@/components/commonTable/commonTable.vue";
export default {

    data() {
        return {
            itemsa: [1, 2, 3],
            objs: {
                "1": 1,
                "2": 2,
                "3": 3
            },
            // 保存内容管理表单对话框布局的数组
            hiddencolumns: [
                ""
            ],
            pl: true,
            hint: '',
            // 批量审核的对话框配置对象
            batchAuditOption: {
                title: "审核内容",
                height: "85.19",
                leftbtn_text: '待审核',
                rightbtn_text: '已审核',
                cancel_reset: true,
                listDeploy: {
                    row: [
                        {
                            cols: 12,
                            subassembly: "VAlert",
                            connector: {
                                props: {
                                    dismissible: true,
                                    border: "left",
                                    color: "#FF4949",
                                    dark: true,
                                    height: 40,
                                    dense: true,
                                    value: true
                                },
                                domProps: {
                                    style: `
                                    margin-top:8px;
                                    background-color:rgba(255,73,73, 0.9);
                                    `
                                },
                                on: {
                                    input: (event) => {
                                        this.batchAuditOption.listDeploy.
                                            row[0].connector.props.value = event;
                                        this.$emit('input', event)
                                    }
                                },
                                ref: 'myRef'
                            },
                            slots: (h) => {
                                return [h('div', { slot: 'default' }, this.hint)]
                            }
                        },
                        {
                            cols: 12,
                            subassembly: commontable,
                            connector: {
                                props: {
                                    fixedHeight: 528,
                                    normalPage: false,
                                    showPagination: false,
                                    dialogTable: true,
                                    headers: [
                                        {
                                            text: "内容ID",
                                            value: "sqlid"
                                        },
                                        {
                                            text: "主标题",
                                            value: "title"
                                        },
                                        {
                                            text: "添加时间",
                                            value: "createtime"
                                        },
                                        {
                                            text: "状态",
                                            value: "ostatus"
                                        }
                                    ],
                                    listData: {
                                    },
                                    itemScope: ['ostatus']
                                },
                                scopedSlots: {
                                    ostatus: ({ item }) => {
                                        let color, text;
                                        if (item.ostatus === 0) {
                                            color = "var(--v-letterGreen-base)"
                                            text = "待审核";
                                        } else {
                                            color = "var(--v-primary-base)";
                                            text = "已审核"
                                        }
                                        return this.$createElement(
                                            'span',
                                            {
                                                domProps: {
                                                    style: `color:${color}`
                                                }
                                            },
                                            text
                                        );
                                    }
                                }
                            }
                        }
                    ]
                }
            },
            // 审核内容的表单配置对象
            auditOption: {
                title: "审核内容",
                height: "85.19",
                leftbtn_text: '待审核',
                rightbtn_text: '已审核',
                cancel_reset: true,
                listDeploy: {
                    row: [
                        {
                            cols: 6,
                            subassembly: "VTextField",
                            property: 'title',
                            connector: {
                                props: {
                                    required: true,
                                    disabled: true,
                                    label: "主标题"
                                }
                            }
                        },
                        {
                            cols: 6,
                            subassembly: "VTextField",
                            property: 'subtitle',
                            connector: {
                                props: {
                                    disabled: true,
                                    label: "副标题"
                                }
                            }
                        },
                        {
                            cols: 6,
                            subassembly: "VTextField",
                            property: 'subtitle3',
                            connector: {
                                props: {
                                    label: "副标题3"
                                }
                            }
                        },
                        {
                            cols: 6,
                            subassembly: "VTextField",
                            property: 'subtitle4',
                            connector: {
                                props: {
                                    label: "副标题4"
                                }
                            }
                        },
                        // {
                        //     cols: 6,
                        //     subassembly: "WAutocomplete",
                        //     property: "iconfont",
                        //     connector: {
                        //         props: {
                        //             label: "选择图标",
                        //             required: true,
                        //             disabled: true,
                        //             items: [
                        //                 { code: 'ticket', desc: '门票' },
                        //                 { code: 'room', desc: '客房' },
                        //                 { code: 'meeting', desc: '会议' },
                        //                 { code: 'package', desc: '套餐' },
                        //                 { code: 'restaurant', desc: '美食' }
                        //             ]
                        //         }
                        //     }
                        // },
                        {
                            cols: 6,
                            subassembly: "VTextField",
                            property: "iconfont",
                            connector: {
                                props: {
                                    label: "选择图标",
                                    required: true,
                                    disabled: true,
                                }
                            }
                        },
                        {
                            cols: 6,
                            subassembly: "WSwitches",
                            property: 'top',
                            disabledDeleteDefault: true,
                            connector: {
                                props: {
                                    extension: {
                                        disabled: true,
                                    },
                                    label: '置顶',
                                    inset: true,
                                    dense: true
                                }
                            },
                        },
                        {
                            cols: 12,
                            subassembly: "MaterialFile",
                            property: 'imgurl',
                            connector: {
                                props: {
                                    fileType: 'essay',
                                    disabled: true,
                                    showAddIcon: false,
                                    title: "图片（750*375）",
                                    label: "上传图片",
                                    hint: '大小不能超过2MB，格式仅限于jpg、png'
                                }
                            }
                        },
                        {
                            cols: 12,
                            subassembly: "MaterialFile",
                            property: 'videourl',
                            connector: {
                                props: {
                                    limit: 1,
                                    remark: 2,
                                    showAddIcon: false,
                                    title: "视频（750*375）",
                                    subTitle: '大小不能超过50MB，格式仅限于MP4、WebM、Ogg格式'
                                }
                            }
                        },
                        {
                            cols: 12,
                            subassembly: "VTextField",
                            property: 'clickurl',
                            title: "跳转链接",
                            connector: {
                                props: {
                                    disabled: true,
                                    placeholder: '跳转链接',
                                }
                            },
                            insertBefore: (<w-cols-title title={'跳转链接'} />),
                        },
                        {
                            cols: 12,
                            subassembly: "VTextarea",
                            property: 'contentinfo',
                            insertBefore: (<w-cols-title bottom={16} title={'简介'} />),
                            connector: {
                                props: {
                                    outlined: true,
                                    rows: "1",
                                    disabled: true,
                                }
                            }
                        },
                        {
                            cols: 12,
                            subassembly: WYSIWYG,
                            property: "richtext",
                            connector: {
                                props: {
                                },
                                domProps: {
                                },
                                class: "bfb"
                            },
                            insertBefore: (<w-cols-title title={'内容'} bottom={16} />),
                        }
                    ]
                },

            },
            pls: false,
            // 新建/编辑表单配置对象
            listDeploy: {
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: 'title',
                        connector: {
                            props: {
                                required: true,
                                label: "主标题"
                            }
                        }
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: 'subtitle',
                        connector: {
                            props: {
                                label: "副标题"
                            }
                        }
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: 'subtitle3',
                        connector: {
                            props: {
                                label: "副标题3"
                            }
                        }
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: 'subtitle4',
                        connector: {
                            props: {
                                label: "副标题4"
                            }
                        }
                    },
                    // {
                    //     cols: 6,
                    //     subassembly: "WAutocomplete",
                    //     property: "iconfont",
                    //     connector: {
                    //         props: {
                    //             label: "选择图标",
                    //             required: true,
                    //             items: [
                    //                 { code: 'ticket', desc: '门票' },
                    //                 { code: 'room', desc: '客房' },
                    //                 { code: 'meeting', desc: '会议' },
                    //                 { code: 'package', desc: '套餐' },
                    //                 { code: 'restaurant', desc: '美食' }
                    //             ]
                    //         }
                    //     }
                    // },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "iconfont",
                        connector: {
                            props: {
                                label: "选择图标",
                                required: true,
                            }
                        }
                    },
                    {
                        cols: 6,
                        subassembly: "WSwitches",
                        property: 'top',
                        disabledDeleteDefault: true,
                        connector: {
                            props: {
                                label: '置顶',
                                inset: true,
                                dense: true
                            }
                        },
                        default: true
                    },
                    {
                        cols: 12,
                        subassembly: "MaterialFile",
                        property: 'imgurl',
                        connector: {
                            props: {
                                fileType: 'essay', // 文章
                                showAddIcon: false,
                                title: "图片（750*375）",
                                label: "上传图片",
                                hint: '大小不能超过2MB，格式仅限于jpg、png'
                            }
                        }
                    },
                    {
                        cols: 12,
                        subassembly: "MaterialFile",
                        property: 'videourl',
                        connector: {
                            props: {
                                title: "视频（750*375）",
                                limit: 1,
                                remark: 2,
                                showAddIcon: false,
                                subTitle: '大小不能超过50MB，格式仅限于MP4、WebM、Ogg格式'
                            }
                        }
                    },
                    {
                        cols: 12,
                        subassembly: "MaterialFile",
                        property: 'audiourl',
                        connector: {
                            props: {
                                subTitle: '大小不能超过20MB，格式仅限于flac、mp3、wav格式',
                                title: '音频',
                                limit: 1,
                                remark: 3,
                                showAddIcon: false,
                            }
                        }
                    },
                    // {
                    //     cols: 12,
                    //     subassembly: (<w-cols-title title={'跳转链接'} />)
                    // },
                    {
                        cols: 12,
                        subassembly: "VTextField",
                        property: 'clickurl',
                        title: "跳转链接",
                        connector: {
                            props: {
                                placeholder: '跳转链接',
                            },
                        },
                        insertBefore: (<w-cols-title title={'跳转链接'} />),
                    },
                    {
                        cols: 12,
                        subassembly: "VTextarea",
                        property: 'contentinfo',
                        insertBefore: (<w-cols-title bottom={16} title={'简介'} />),
                        connector: {
                            props: {
                                outlined: true,
                                rows: "1",
                            }
                        }
                    },
                    {
                        cols: 12,
                        subassembly: tinymces,
                        property: "richtext",
                        connector: {
                            props: {
                                top: 16
                            }
                        },
                        insertBefore: (<w-cols-title title={'内容'} />)
                    }
                ]
            }
        }
    },
    methods: {
        additems() {
            // vue3可以响应式数组直接赋值, 而不是只能监听赋值方法
            // this.itemsa[this.itemsa.length] = this.itemsa.length + 1;
            this.itemsa.push(this.itemsa.length + 1);

            // 试试对象
            // 对象也是不能直接赋值更新，在3中可以
            // this.objs[this.itemsa.length] = this.itemsa.length;
            // 备注：没有像数组那样更新的方法
            console.log(this.objs)
        },
        /**
         * 修改审核内容方法
         * @param {*} arr 审核数据项id集合
         * @param {*} status 0=未审核，1=已审核
         */
        contentStatus(arr, status = 0, cb) {
            this.axios.post(this.api.change_contentStatus, {
                ids: [...arr],
                status
            }).then(res => {
                if (res.code === this.staticVal.Code.Success) {
                    if (status === 0) {
                        this.snackbar.success("已待审核");
                    } else {
                        this.snackbar.success("审核通过");
                    }
                    this.list();
                    cb && cb(true);
                }
            });
        },
        // 审核（单选）
        resetForm() {
            this.contentStatus([this.id], 0);
            this.auditDialog = false;
        },
        validateSave(form, cab) {
            this.contentStatus([this.id], 1);
            cab(true);
        },
        // 审核（全选）
        batchResetForm() {
            this.contentStatus(this.ids, 0)
            this.batchAuditDialog = false;
        },
        batchValidateSave(form, cab) {
            this.contentStatus([...this.ids], 1, cab);
        },

        // 创建表单组件数据结构
        creationForm(obj = {}, row = [], bool) {
            let len = row.length;
            console.log(this.hiddencolumns)
            for (let i = 1; i < len; i++) {
                // console.log(row[i])
                if (this.hiddencolumns.length === 0 || this.hiddencolumns.length === 1 &&
                    this.hiddencolumns[0] === "") {

                    if (row[i].property === "iconfont") {
                        row[i].setCol = {
                            style: {
                                display: "none !important"
                            }
                        }
                    } else {
                        row[i].setCol = {
                            style: {
                                display: "block"
                            }
                        }
                    }
                    let item = row[i];
                    if (typeof item.connector.props.required === "boolean") {
                        if (row[i].property === "iconfont") {
                            row[1].connector.props.required = false;
                            item.connector.props.required = false;
                        } else {
                            item.connector.props.required = true;
                        }
                    }
                    let props = row[i].connector.props;
                    if (row[i].property === 'imgurl'
                        || row[i].property === 'videourl') {
                        props.title = `${props.title[0]}${props.title[1]}
                        ${obj.picsize?'（' + obj.picsize +'）':''}`
                    }
                } else if (this.hiddencolumns.includes(row[i]?.property)) {
                    row[i].setCol = {
                        style: {
                            display: "none !important"
                        }
                    }
                    let item = row[i];
                    if (typeof item.connector.props.required === "boolean") {
                        item.connector.props.required = false;
                    }
                } else {
                    row[i].setCol = {
                        style: {
                            display: "block"
                        }
                    }
                    let item = row[i];
                    if (typeof item.connector.props.required === "boolean") {

                        item.connector.props.required = true;
                    }
                    let props = row[i].connector.props;
                    if (row[i].property === 'imgurl'
                        || row[i].property === 'videourl') {
                        props.title = `${props.title[0]}${props.title[1]}${obj.picsize?'（' + obj.picsize +'）':''}`
                    }
                }
            }
            if (bool) {
                for (const [index, item] of row.entries()) {
                    // console.log(typeof obj[item.property])
                    if (item.subassembly === 'WFileinput') {
                        // this.$set(
                        //     item.connector.props,
                        //     'items',
                        //     obj[item.property]
                        // );
                        // delete item.connector.props.items;
                        item.connector.props.items = obj[item.property];

                    } else {
                        // delete item.connector.props.value;
                        // item.connector.props.value = obj[item.property];
                        item.default = obj[item.property];
                        // this.$set(
                        //     item.connector.props,
                        //     "value",
                        //     "999"
                        // );
                        // this.$set(
                        //     item,
                        //     "default",
                        //     ""
                        // );

                        // item.connector.props.default = "";
                        // item.connector.props.value = "";
                        // this.$set(
                        //     this.listDeploy.row,
                        //     index,
                        //     item
                        // );

                        // delete item.connector.props.value;
                        // item.connector.props.default = obj[item.property];
                    }
                }
                // this.$set(this.listDeploy, 'row', row)
                // this.$set(this,'listDeploy', {
                //     row: row
                // })
            }
        }
    },
}
