<template>
  <div>
    <!-- <ul>
      <li v-for="(item,index) in objs" :key="index" @click="additems">
      {{item}}
    </li>
    </ul> -->
    <formMenu
      :dialog.sync="auditDialog"
      title="审核内容"
      height="85.19vh"
      :center="true"
      transition=""
      :center_btn="true"
      v-bind="auditOption"
      @resetForm="resetForm"
      @validateSave="validateSave"
      ref="edg"
    ></formMenu>
    <formMenu
      :dialog.sync="batchAuditDialog"
      title="审核内容"
      height="85.19vh"
      :center="true"
      transition=""
      :center_btn="true"
      v-bind="batchAuditOption"
      @resetForm="batchResetForm"
      @validateSave="batchValidateSave"
    ></formMenu>
    <common-table
      :itemScope="['ostatus', 'top']"
      :headers="headers"
      :listData="listData"
      ref="ct"
      pageTitle="内容管理"
      :loading="loading"
      @search="search"
      @remove="remove"
      @save="save"
      @edit="edit"
      @changePage="changePage"
      :btnActionEvent="btnActionEvent"
      :btnAction="true"
      :leftConfig="leftConfig"
      :rightConfig="rightConfig"
      :listDeploy="listDeploy"
      @otherRightOperate="otherRightOperate"
      @otherTableOperate="otherTableOperate"
      pageKey="seq"
      :showSelect="true"
      @getSelectItems="getSelectItems"
    >
      <template v-slot:ostatus="{ item }">
        <span
          :style="{
            color: item.ostatus == 0 ? 'var(--v-primary-base)' : '#11B211',
          }"
        >
          {{ item.ostatus ? "已审核" : "待审核" }}
        </span>
      </template>
      <template v-slot:top="{ item }">
        <w-switches
          inset
          label=""
          color="primary"
          @change="updateStatus(item.sqlid)"
          v-model="item.top"
          hide-details
          dense
        />
      </template>
    </common-table>

  </div>
</template>

<script>
import formMenu from "@/components/form-menu/form-menu.vue";
import form from "./content-manage-form.js";
import commonCurd from "@/mixin/commonCurd";
import api from "@/api/menu-set-card/menu-set-card.js";
// import ClipboardJS from "clipboard";
export default {
  components: {
    formMenu,
  },
  mixins: [form, commonCurd],
  data() {
    return {
      api,
      auditDialog: false,
      batchAuditDialog: false,
      menuBool: false,
      headers: [
        { text: "内容ID", value: "contentid" },
        { text: "排序号", value: "seq" },
        { text: "标题", value: "title" },
        { text: "添加时间", value: "createtime" },
        { text: "状态", value: "ostatus" },
        { text: "是否置顶", value: "top" },
        { text: "操作", value: "actions", width: 120 },
      ],
      btnActionEvent: [
        {
          event: "editItem",
          icon: "bianji2-fill",
          color: "primary",
          tooltip: "编辑",
        },
        // {
        //   event: "Movement",
        //   icon: "yidongduan",
        //   color: "primary",
        //   tooltip: "移动端",
        //   class: "copy",
        // },
        {
          event: "audit",
          icon: "shenhe-fill",
          color: "primary",
          tooltip: "审核",
        },
        {
          event: "upMove",
          icon: "shengxu",
          color: "primary",
          tooltip: "上移",
        },
        {
          event: "downMove",
          icon: "jiangxu",
          color: "primary",
          tooltip: "下移",
        },
        {
          event: "deleteItem",
          icon: "shanchu-fill",
          color: "var(--v-redness-base)",
          tooltip: "删除",
        }
      ],
      // 定义左侧搜索框
      leftConfig: [
        { type: "input", label: "输入标题", property: "searchkey" },
        {
          type: "select",
          label: "选择状态",
          property: "status",
          items: [
            {
              code: 0,
              desc: "待审核",
            },
            {
              code: 1,
              desc: "已审核",
            },
          ],
        },
      ],
      fixedParams: {
        type: "M",
        menuid: "",
      },
      // 定义右侧按钮
      rightConfig: [
        {
          label: "批量删除",
          icon: "shanchu-fill",
          event: "remove",
          color: "redness white--text",
          disabled: true,
        },
        {
          label: "批量审核",
          icon: "shenhe-fill",
          event: "batchAudit",
          disabled: true,
        },
        { label: "刷新", icon: "shuaxin", event: "refresh" },
        { label: "新建", icon: "zengjia", event: "add-load" },
      ],
      clipboard: null, // 声明给实例化复制类的时候保存的实例对象
      clickurl: "", // 保存每次点击复制按钮的时候当前数据项的url地址
      id: "", // 保存审核按钮点击的当前数据项sqlid
      ids: [], // 保存表格选择的集合
      selects_bool: true, // 声明表格组件为选择发生为集合类型
      removefield: "ids",
      eids: [], // 保存选中的数据项对象数组集合
      items: [],
    };
  },
  methods: {
    beforeSave(form) {
      if (form.sqlid === 0) {
        form.menuid = this.fixedParams.menuid;
        return;
      }
      if (!form.menuid) {
        form.menuid = this.fixedParams.menuid;
      }
    },
    otherTableOperate(e, item) {
      // 判断移动端复制url按钮
      if (e === "Movement") {
        this.clickurl = item.clickurl;
        return;
      }

      // 判断打开审核内容对话框按钮
      if (e === "audit") {
        let listDeploy = this.auditOption.listDeploy;
        this.axios.post(this.api.load, { sqlid: item.sqlid }).then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            this.hiddencolumns = res.data.hiddencolumn;
            this.creationForm(res.data, listDeploy.row,false);
            for (const obj of listDeploy.row) {
              let val = item[obj.property];
              if (obj.subassembly !== "div") {
                delete obj.default;
              }
              if (obj.connector?.props) {
                // delete obj.connector.props.value;
                if (obj.subassembly === "WFileinput") {
                  if (obj.property === "imgurl") {
                    this.$set(this.auditOption.listDeploy.row[5].connector.props, 'items', '');
                  }else {
                    this.$set(this.auditOption.listDeploy.row[6].connector.props, 'items', '');
                  }
                }
              }
              console.log(val,obj.property)
              if (val || val === 0) {
                // obj.default = val;
                this.$set(obj, "default", val);
                if (obj.subassembly === "WFileinput") {
                  obj.connector.props.items = val;
                  this.$set(obj.connector.props, "items", val);
                  // if (obj.property === "imgurl") {
                  //   this.$set(this.auditOption.listDeploy.row[4].connector.props, 'items', '');
                  // }else {
                  //   this.$set(this.auditOption.listDeploy.row[5].connector.props, 'items', '');
                  // }
                }
              }
            }
            this.auditOption.listDeploy = listDeploy;
            console.log(this.auditOption.listDeploy.row);
            this.id = item.sqlid;
            this.auditDialog = true;
          }
        });
        return;
      }

      // 判断点击上下排序移动按钮
      if (e === "upMove") {
        // 上
        this.axios
          .post(this.api.changeContentSeq, {
            lup: e === "upMove" ? true : false,
            sqlid: item.sqlid,
          })
          .then((res) => {
            if (res.code === this.staticVal.Code.Success) {
              this.snackbar.success("更新排序成功");
              this.list();
            }
          });
      }
      if (e === "downMove") {
        // 下
        this.axios
          .post(this.api.changeContentSeq, {
            lup: e === "upMove" ? true : false,
            sqlid: item.sqlid,
          })
          .then((res) => {
            if (res.code === this.staticVal.Code.Success) {
              this.snackbar.success("更新排序成功");
              this.list();
            }
          });
      }
    },
    otherRightOperate(e) {
      if (e === "refresh") {
        return this.list();
      }
      if (e === "remove") {
        if (this.ids.length > 0) {
          // this.remove(this.ids);
          this.confirm
            .showConfirm("确定批量删除内容管理吗？")
            .then(() => {
              this.remove(this.ids, () => {
                this.rightConfig[0].disabled = true;
                this.rightConfig[1].disabled = true;
              });
            })
            .catch(() => {});
        }
        return;
      }
      if (e === "batchAudit") {
        let num = 0;
        for (let i = 0, len = this.eids.length; i < len; i++) {
          // 已审核的值为1，每次相加只有已审核的才会变化num
          num += this.eids[i].ostatus;
        }
        // this.$set(
        //   this.batchAuditOption.listDeploy.row[0],
        //   "slots",
        //   `此操作将审核以下${this.eids.length}篇内容，已选择${num}篇已审核，${
        //     this.eids.length - num
        //   }篇未审核，确定吗？`
        // );
        this.hint = `此操作将审核以下${
          this.eids.length
        }篇内容，已选择${num}篇已审核，${
          this.eids.length - num
        }篇未审核，确定吗？`;

        this.$set(
          this.batchAuditOption.listDeploy.row[1].connector.props,
          "listData",
          {
            records: this.eids,
          }
        );
        this.batchAuditOption.listDeploy.row[0].connector.props.value = true;
        this.batchAuditDialog = true;
        return;
      }
      if (e === "add-load") {
        this.addload();
      }
    },

    addload() {
      this.axios
        .post(this.api.load, {
          sqlid: -1,
          groupid: this.fixedParams.menuid,
        })
        .then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            this.hiddencolumns = res.data.hiddencolumn;
            // console.log(res);
            this.creationForm(res.data, this.listDeploy.row,true);
            this.listDeploy.sqlid = 0;
            this.$refs.ct.formTitle = "新建内容管理";
            this.$refs.ct.formDialog = true;
          }
        });
    },
    async createdExtend() {
      this.loading = true;
      await new Promise((res, rej) => {
        setTimeout(() => {
          res();
        }, 500);
      });
    },
    getSelectItems(items) {
      this.items = items;
      let ids = items.map((n) => n.sqlid);
      this.eids = items;
      this.ids = ids;
      if (ids.length > 0) {
        this.rightConfig[0].disabled = false;
        this.rightConfig[1].disabled = false;
      } else {
        this.rightConfig[0].disabled = true;
        this.rightConfig[1].disabled = true;
      }
    },
    *switchPage() {
      let state = false;
      while (true) {
        if (state) {
          this.list();
        } else {
          state = true;
        }
      }
    },
    // 直接在表格上点击修改状态
    updateStatus(sqlid) {
      this.loading = true;
      let projectId = localStorage.getItem("projectId");
      this.axios
        .post(this.api.updateStatus, { sqlid: sqlid, projectId })
        .then((r) => {
          this.loading = false;
          if (r.code === this.staticVal.Code.Success) {
            this.snackbar.success("更新成功！");
            this.list();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    beforeEdit(form) {
      this.hiddencolumns = form.hiddencolumn;
      this.creationForm(form, this.listDeploy.row);
    },
  },
  // // 离开页面生命周期函数
  // beforeRouteLeave(to, form, next) {
  //   // 销毁插件
  //   this.clickurl.destroy();
  //   next();
  // },
  mounted() {
    // this.clipboard = new ClipboardJS(".copy", {
    //   text: (e) => {
    //     let copys = document.getElementsByClassName("copy");
    //     console.log(this.clickurl, e);
    //     for (let i = 0, len = copys.length; i < len; i++) {
    //       if (copys[i] === e) {
    //         console.log(true);
    //       }
    //     }
    //     // 注意：如果返回的是空值类型或者false将不会回调success函数也不会调用error
    //     return "0";
    //   },
    // });
    // this.clipboard.on('success', (e)=> {
    //   this.snackbar.success("复制成功！")
    // });
    // this.clipboard.on('error', (e)=> {
    //   this.snackbar.error("复制失败！")
    // });
  },
  watch: {
    "$store.state.header": {
      handler(v) {
        if (v) {
          console.log(v);
          this.fixedParams.menuid = v.code;
          if (this.menuBool) {
            this.rightConfig[0].disabled = true;
            this.rightConfig[1].disabled = true;
            this.list();
          } else {
            this.menuBool = true;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.bfb {
  img {
    width: 100%;
  }
}
</style>
