export default {
	data() {
		return {
			pageSizeKey: 'pageSizeKey',
			listData: {},
			loading: false,
			form: {
				pages: {
					"currentpage": 1,
					"sortorder": "descending",
					"pagesize": 20
				}
			},
			// 固定参数
			fixedParams: {},
			selects_bool: false,
			removefield: "sqlid",
			mandatoryCreate: false
		}
	},
	methods: {
		search(form) {
			this.form = {pages: this.form.pages};
			this.form = Object.assign(this.form, form);
			this.list();
		},
		changePage(currentPage, itemsPerPage) {
			this.form.pages.currentpage = currentPage;
			this.form.pages.pagesize = itemsPerPage;
			this.list();
		},
		list() {
			this.loading = true;
			if (JSON.stringify(this.fixedParams) !== '{}') {
				this.form = Object.assign(this.form, this.fixedParams);
			}
			if (this.form.pages) {
				this.form.pages.sortname = this.sortname ? this.sortname : 'sqlid';
			}
			this.axios.post(this.api.list, this.form).then(res => {
				this.loading = false;
				this.listData = res.data;
			}).catch(() => {
				this.loading = false;
			});
		},
		async save(form, callback) {
			form.sqlid = this.mandatoryCreate ? 0: this.listDeploy.sqlid;
			await this.beforeSave(form);
			let save = this.api.save;
			if (this.api.add && form.sqlid === 0) {
				save = this.api.add;
				form = JSON.parse(JSON.stringify(form));
			} else if (this.api.update && form.sqlid !== 0) {
				save = this.api.update;
			}
			this.axios.post(save, form).then(r => {
				if (r.code === this.staticVal.Code.Success) {
					callback();
					this.snackbar.success(this.listDeploy.sqlid ? "保存成功！" : "新建成功！")
					this.list();
					this.afterSave(r.data);
				} else {
					this.$refs.ct.$refs.formRef.load = false;
				}
			});
		},
		// 获取单个对象，用于表单对象的填充
		async getObject(sqlid) {
			let axiosResponse = await this.axios.post(this.api.load, {sqlid: sqlid});
			if (axiosResponse.code === this.staticVal.Code.Success) {
				return axiosResponse.data;
			}
			return null;
		},
		async edit(sqlid, callback, finallyCallback, mandatoryCreate) {
			this.mandatoryCreate = mandatoryCreate
			let form = await this.getObject(sqlid);
			if (form) {
				await this.beforeEdit(form);
				this.listDeploy.sqlid = sqlid;
				for (const obj of this.listDeploy.row) {
					let val = form[obj.property];
					let arr = ['startdate', 'startsell', 'startget']
					if (obj.property instanceof Array && arr.includes(obj.property[0])) {
						val = `${form[obj.property[0]]} ~ ${form[obj.property[1]]}`
					}
					if (obj.hasEditDisabledProperty) {
						obj.connector.props.disabled = true;
					}
					if (obj.subassembly !== 'div') {
						obj.default = val;
					}
					if (obj.subassembly === 'WFileinput') {
						obj.connector.props.items = val;
					}
				}
				// 赋值完成调用方法执行打开模态框
				if (callback) {
					callback();
				}
			} else {
				if (finallyCallback) {
					finallyCallback();
				}
			}
		},
		// 直接在表格上点击修改状态
		updateStatus(sqlid) {
			this.loading = true;
			this.axios.post(this.api.updateStatus, {sqlid: sqlid}).then(r => {
				this.loading = false;
				if (r.code === this.staticVal.Code.Success) {
					this.snackbar.success("更新成功！");
				}
			}).catch(() => {
				this.loading = false;
			});
		},
		remove(id, callback, sqlid = this.removefield, s = this.selects_bool) {
			this.loading = true;
			// 判断当前页面有没有设置声明选择项为一个集合，如果是，还需要判断当前id是不是一个数组
			if (s && !(id instanceof Array)) {
				id = [id];
			}
			this.axios.post(this.api.remove, {[sqlid]: id}).then(r => {
				this.loading = false;
				if (r.code === this.staticVal.Code.Success) {
					this.snackbar.success("删除成功！");
					if (callback) {
						callback();
					}
					this.list();
				}
			});
		},
		async createdExtend() {
			// 单独调用直接覆盖此方法
		},
		async beforeSave() {

		},
		async beforeEdit() {

		},
		afterSave() {

		},
		exportFile(form, callback) {
			const params = Object.assign(this.form, form);
			this.axios.post(this.api.download, params, {responseType: 'blob'}).then((res) => {
				const blob = new Blob([res], {type: 'application/vnd.ms-excel;charset=utf-8'})
				const blobURL = window.URL.createObjectURL(blob)
				const tempLink = document.createElement('a')
				tempLink.style.display = 'none'
				tempLink.href = blobURL
				tempLink.setAttribute('download', decodeURI(this.$refs.ct.pageTitle))
				if (typeof tempLink.download === 'undefined') {
					tempLink.setAttribute('target', '_blank')
				}
				document.body.appendChild(tempLink)
				tempLink.click()
				document.body.removeChild(tempLink)
				window.URL.revokeObjectURL(blobURL)
				if (callback) {
					callback()
				}
			})
		}
	},
	async mounted() {
		let pageSize = localStorage.getItem(this.pageSizeKey);
		this.form.pages.pagesize = pageSize ? parseInt(pageSize) : 20;
		await this.createdExtend();
		this.list();
	}
}
