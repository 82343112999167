<template>
  <v-card class="mx-auto pa-3" outlined>
    <div ref="sjjsd"></div>
  </v-card>
</template>

<script>
import props from "./WYSIWYG-props.js";
export default {
  mixins: [props],
  data() {
    return {};
  },
  watch: {
    value: {
      handler(e) {
        this.$nextTick(() => {
          this.$refs.sjjsd.innerHTML = e;
        });
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>